import React from "react";

import BannerWrapperCustomImg from "../../top_banner/banner_wrapper_custom_img";
import { useTranslation } from 'react-i18next';

const Cookies = () => {
  const {t} = useTranslation();
  return (
    <div className={`font-sans`}>
      <BannerWrapperCustomImg
        largeBgImg={"https://d2lxkizvrhu4im.cloudfront.net/images/background-cookie-banner.png"}
        nonlargeBgImg={"https://d2lxkizvrhu4im.cloudfront.net/images/background-cookie-banner.png"}
      >
        <div className=" flex flex-col  justify-center items-center h-full py-20  mx-auto">
          <div class="font-bold text-3xl md:text-6xl text-center">
            <h1>{t('cookie_policy')} </h1>
          </div>
          <div class=" text-sm md:text-xl  font-medium  text-center  lg:text-left leading-relaxed px-1 md:px-0">
            {t('last_updated_march_22_2022')}
          </div>
        </div>
      </BannerWrapperCustomImg>

      <div className={`mx-auto w-11/12 lg:w-5/6 mb-16`}>
        <div className="my-8 font-bold">
          1. {t('what_is_a_cookie')}
        </div>
        <div className="">
          {t('a_cookie_is_a_small_text_file')}
        </div>
        <div className="my-8 font-bold">
          2. {t('by_whom_are_cookies_placed')}
        </div>
        <div className="">
          {t('cookies_are_placed')}
        </div>
        <div className="my-8 font-bold">
          3. {t('how_are_cookies_used_on_mereos')}
        </div>
        <div className="">
          {t('mereos_uses_four_types_of_cookies')}
          <li className="ml-8">
            {t('strictly_necessary_cookies')}
          </li>
          <li className="ml-8">
            {t('functional_cookies_allow')}
          </li>
          <li className="ml-8">
            {t('performance_cookies')}
          </li>
          <li className="ml-8">
            {t('marketing_or_audience')}
          </li>
        </div>
        <div className="my-8 font-bold">
          4. {t('how_long_do_we_keep_your_cookies')}
        </div>
        <div className="">
          {t('your_cookies_are_kept')}
        </div>
        <div className="my-8 font-bold">
          5. {t('cookie_management')}
        </div>
        <div className="">
          {t('you_can_deactivate_cookies')}
          <div className="font-bold my-2">
            {t('however_we_would_like')}
          </div>
          {t('the_configuration_of_each_browser')}
          <li className="ml-8">
            {`${t('for')} Chrome: `}
            <a
              href='https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en'
              className="text-blue-600"
              target={'_blank'}
            >
              https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en
            </a>
          </li>
          <li className="ml-8">
            {`${t('for')} Internet Explorer: `}
            <a
              href='https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies'
              className="text-blue-600"
              target={'_blank'}
            >
              https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies
            </a>
          </li>
          <li className="ml-8">
            {`${t('for')} Safari: `}
            <a
              href='https://support.apple.com/fr-fr/guide/safari/sfri11471/mac'
              className="text-blue-600"
              target={'_blank'}
            >
              https://support.apple.com/fr-fr/guide/safari/sfri11471/mac
            </a>
          </li>
          <li className="ml-8">
            {`${t('for')} Firefox: `}
            <a
              href='http://support.mozilla.org/fr/kb/cookies-informations-sites-enregistrent?redirectlocale=fr&redirectslug=G%C3%A9rer+les+cookies'
              className="text-blue-600"
              target={'_blank'}
            >
              http://support.mozilla.org/fr/kb/cookies-informations-sites-enregistrent?redirectlocale=fr&redirectslug=G%C3%A9rer+les+cookies
            </a>
          </li>
          <li className="ml-8">
            {`${t('for')} Opera: `}
            <a
              href='http://help.opera.com/Windows/10.20/fr/cookies.html'
              className="text-blue-600"
              target={'_blank'}
            >
              http://help.opera.com/Windows/10.20/fr/cookies.html
            </a>
          </li>
          <span>
            {t('in_any_case_you_will_find')} <a href="http://www.youronlinechoices.com/fr/et" target="_black" className="text-blue-600">http://www.youronlinechoices.com/fr/et</a> {t('google_at_following_address')} : <a href="https://policies.google.com/technologies/partner-sites" className="text-blue-600" target={'_blank'}>https://policies.google.com/technologies/partner-sites</a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Cookies;
